import React from "react";
import { Row, Col } from "react-bootstrap";
import Logo from "../../../images/partners/salesforce.png";

const sales = () => {
  return (
    <div className="partner-detail-card">
      <Row>
        <div className="top-section">
          <div>
            <img className="img-fluid" src={Logo} alt="PartnerImage" />
          </div>
          <div className="padding-add">
            <h3>Salesforce</h3>
            <div className="lbl-ptnrn">Salesforce Consulting Partner </div>
            <div>
              <a
                href="https://appexchange.salesforce.com/appxConsultingListingDetail?listingId=a0N4V00000IdvYGUAZ"
                target="_blank"
              >
                Visit Website
              </a>{" "}
              <span></span>
            </div>
          </div>
        </div>
      </Row>
      <Row>
        <Col>
          <div className="mt-3">
            <h4>About Salesforce</h4>
            <p>
              Salesforce is the world’s most trusted customer relationship
              management (CRM) platform. Salesforce help marketing, sales,
              commerce, service and IT teams work as one from anywhere. Another
              thing about our CRM platform: It’s software, not hardware, and it
              lives in the cloud. This means your marketing, sales, commerce,
              service, and IT teams can be connected on our platform no matter
              where they’re physically located in the world. And now with Slack,
              Customer 360 is the world’s #1 CRM that simplifies communication
              and information sharing between customers, teams, and partners to
              power digital workflows for the new way to work. This digital
              workplace helps businesses, government agencies, schools, and
              non-profit organizations large and small deliver exceptional
              experiences. And it’s doing so across industries, including
              healthcare, retail, consumer goods, manufacturing, financial
              services, media, communications and technology.
            </p>
            <p>
              A significant part of iTelaSoft work is currently leveraging
              Salesforce as the could platform to implement Applications and
              support Salesforce customers to achieve their digital
              transformation.
            </p>
          </div>
          <div className="mt-3">
            <h4>Focus Areas</h4>
            <div className="p"><span className="vdivide">-{" "}</span>Salesforce Development & Implementation </div>
            <div className="p"><span className="vdivide">-{" "}</span>Salesforce License </div>
            <div className="p"><span className="vdivide">-{" "}</span>AppExchange App Developments </div>
            <div className="p"><span className="vdivide">-{" "}</span>Architectural Guidance  </div>
            <div className="p"><span className="vdivide">-{" "}</span>Admin as a Service  </div>
            <div className="p"><span className="vdivide">-{" "}</span>System Integration</div>
          </div>
          <div className="mt-3">
            <h4>Operating Countries</h4>
            <p>Australia <span className="vdivide">|</span> Singapore <span className="vdivide">|</span> UK <span className="vdivide">|</span> India <span className="vdivide">|</span> EMEA</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default sales;
